#chatWindow::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 20px;
    border: 3px solid lightgray;
    /* width: 5px; */
}

#chatWindow::-webkit-scrollbar {
    background: #d4d5ce;
    border-radius: 20px;
    width: 10px;
}

#chatOpen {
    animation: starts 1s forwards ;
}

#chatClosed {
    animation: close 1s forwards ;
}

@keyframes tickW {
    0% {
        box-shadow: 0 0 10px 0px #43998c;
    }
    100% {
        box-shadow: 0 0 5px 10px #43998c;
    }
}

@keyframes tickB {
    0% {
        box-shadow: 0 0 10px 0px rebeccapurple;
    }
    100% {
        box-shadow: 0 0 5px 10px rebeccapurple;
    }
}

@keyframes starts {
    0% {
        width: 50px;
        min-width: auto;
        max-width: auto;
        height: 50px;
        min-height: auto;
        min-height: auto;
        border-radius: 50px;
    }
    50% {
        width: 30%;
        min-width: 300px;
        max-width: 400px;
        height: 50px;
        max-height: auto;
        min-height: auto;
        border-radius: 50px;
    }
    100% {
        width: 30%;
        min-width: 300px;
        max-width: 400px;
        height: 50%;
        max-height: 600px;
        min-height: 450px;
        border-radius: 50px 20px 10px 10px;
    }
}

@keyframes close {
    0% {
        width: 30%;
        min-width: 300px;
        max-width: 400px;
        height: 50%;
        max-height: 600px;
        min-height: 450px;
        border-radius: 50px 20px 10px 10px;
    }
    50% {
        width: 30%;
        min-width: 300px;
        max-width: 400px;
        height: 50px;
        max-height: auto;
        min-height: auto;
        border-radius: 50px;
    }
    100% {
        height: 50px;
        max-height: auto;
        min-height: auto;
        width: 50px;
        min-width: auto;
        max-width: auto;
        border-radius: 50px;
    }
}

#chatFile {
    width: 100%;
}

#chatDocs {
    background-color: azure;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid burlywood;
    display: table-cell;
    width: 100%;
    text-decoration: none;
    color: cornflowerblue;
}

#chatDocs > p {
    margin: 0;
    padding: 0;
}