@font-face {
    font-family: 'Vasek';
    src: url('../fonts/Vasek Italic_0.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

#neonDiv {
    display: flex;
    position: fixed;
    flex-direction: row;
    align-items: center;
    /*z-index: 5;*/
    opacity: 0;
    animation: startH 3s ease-in-out forwards ;
    cursor: default;
}

@media screen and (min-aspect-ratio: 86/90) {

    #neonDiv {
        top: 50px;
        left: 100vw;
        margin-left: -250px;
    }

}

@media screen and (max-aspect-ratio: 85/90) {

    #neonDiv {
        bottom: -50px;
        margin-left: 50px;
    }

}

@media screen and (width < 500px) {
    #neonH2, #neonH2F, #neonH2l {
        margin-top: 30px;
        font-family: Vasek;
        font-size: 100px;
        color: #0fa;
        text-shadow: 0 0 21px #89ffd8,
            0 0 42px #0fa;
    }
}

@media screen and (width >= 500px) {
    #neonH2, #neonH2F, #neonH2l {
        margin-top: 30px;
        font-family: Vasek;
        font-size: 100px;
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 151px #0fa;
        animation: pulsate 0.11s ease-in-out infinite alternate;
        /*animation: fSimbol infinite 10s linear;*/
    }
    #neonH2F {
        animation: fSimbol infinite 10s linear;
    }
}

#neonH2W, #neonH2FW, #neonH2lW {
    margin-top: 30px;
    font-family: Vasek;
    font-size: 100px;
    color: #0e7a60;
}

@keyframes startH {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

#neonH2l, #neonH2lW {
    transform: translate(5px, 16px) rotateZ(62deg)
}

#neonH2, #neonH2l {
    /*animation: lights infinite 10s linear;*/
    /*animation: pulsate 0.11s ease-in-out infinite alternate;*/
}

@keyframes pulsate {
    100% {
        /* Larger blur radius */
        text-shadow:
        0 0 4px #fff,
        0 0 19px #fff,
        0 0 40px #0fa,
        0 0 150px #0fa;
    }
    0% {
        /* A slightly smaller blur radius */
        text-shadow:
        0 0 4px #fff,
        0 0 18px #fff,
        0 0 38px #0fa,
        0 0 140px #0fa;
    }
}

@keyframes lights {
    0% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    10% {
        color: #f0ffe0;
        text-shadow: 
            0 0 19px #fff,
            0 0 86px #0fa,
            0 0 138px #0fa;
    }
    20% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 22px #fff,
            0 0 43px #0fa,
            0 0 93px #0fa,
            0 0 152px #0fa;
    }
    30% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    40% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    49% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    50% {
        color: #c0dfc0;
        text-shadow: 
            0 0 7px #fff,
            0 0 30px #0fa,
            0 0 50px #0fa;
    }
    59.9% {
        color: #c0dfc0;
        text-shadow: 
            0 0 7px #fff,
            0 0 30px #0fa,
            0 0 50px #0fa;
    }
    60% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    60.1% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 14px #fff,
            0 0 24px #fff,
            0 0 49px #0fa,
            0 0 89px #0fa,
            0 0 99px #0fa,
            0 0 112px #0fa,
            0 0 161px #0fa;
    }
    70% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 14px #fff,
            0 0 24px #fff,
            0 0 49px #0fa,
            0 0 89px #0fa,
            0 0 99px #0fa,
            0 0 112px #0fa,
            0 0 161px #0fa;
    }
    80% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 13px #fff,
            0 0 23px #fff,
            0 0 45px #0fa,
            0 0 85px #0fa,
            0 0 95px #0fa,
            0 0 107px #0fa,
            0 0 154px #0fa;
    }
    89.9% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    100% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
}


@keyframes fSimbol {
    0% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    10% {
        color: #f0ffe0;
        text-shadow: 
            0 0 19px #fff,
            0 0 86px #0fa,
            0 0 138px #0fa;
    }
    20% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 22px #fff,
            0 0 43px #0fa,
            0 0 93px #0fa,
            0 0 152px #0fa;
    }
    21% {
        color: #00402B;
        text-shadow: 0 0 7px #000,
            0 0 21px #000,
            0 0 82px #000,
            0 0 92px #000,
            0 0 151px #000;
    }
    30% {
        color: #00402B;
        text-shadow: 0 0 7px #000,
            0 0 21px #000,
            0 0 82px #000,
            0 0 92px #000,
            0 0 151px #000;
    }    
    31% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    32% {
        color: #00402B;
        text-shadow: 0 0 7px #000,
            0 0 21px #000,
            0 0 82px #000,
            0 0 92px #000,
            0 0 151px #000;
    } 
    40% {
        color: #00402B;
        text-shadow: 0 0 7px #000,
            0 0 21px #000,
            0 0 82px #000,
            0 0 92px #000,
            0 0 151px #000;
    }
    50% {
        color: #00402B;
        text-shadow: 0 0 7px #000,
            0 0 21px #000,
            0 0 82px #000,
            0 0 92px #000,
            0 0 151px #000;
    }    
    51% {
        color: #c0dfc0;
        text-shadow: 
            0 0 7px #fff,
            0 0 30px #0fa,
            0 0 50px #0fa;
    }
    60% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    60.1% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 24px #fff,
            0 0 49px #0fa,
            0 0 99px #0fa,
            0 0 161px #0fa;
    }
    70% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 24px #fff,
            0 0 49px #0fa,
            0 0 99px #0fa,
            0 0 161px #0fa;
    }
    80% {
        color: #ffffff;
        text-shadow: 0 0 7px #fff,
            0 0 23px #fff,
            0 0 45px #0fa,
            0 0 95px #0fa,
            0 0 154px #0fa;
    }
    89.9% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
    100% {
        color: #f0fff0;
        text-shadow: 0 0 7px #fff,
            0 0 21px #fff,
            0 0 42px #0fa,
            0 0 92px #0fa,
            0 0 151px #0fa;
    }
}